var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"site-list"},[_c('div',{staticClass:"site-item_list"},[_c('van-pull-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.siteList),function(item,index){return _c('div',{key:index,staticClass:"site-item VDS-ShadowBg_home flex"},[(item.masterImg)?_c('img',{staticClass:"sitezz-img",attrs:{"src":item.masterImg}}):(item.stationTagCode == 'FAILURE')?_c('img',{staticClass:"sitezz-img",attrs:{"src":_vm.sitefailure}}):_c('img',{staticClass:"sitezz-img",attrs:{"src":_vm.sitezz}}),_c('div',{staticClass:"width_full",on:{"click":function($event){return _vm.siteDetailClick(item.stationNo, item.marketerNo, item.stationStatusCode)}}},[_c('div',{staticClass:"site-item_right flex"},[_c('div',{staticClass:"site-item_one flex"},[_c('div',{staticClass:"flex",staticStyle:{"height":"20px"}},[(item.stationType && item.stationType != '---' && item.hasMonitor != 0)?_c('div',{staticClass:"stationName",staticStyle:{"height":"20px"}},[_vm._v(_vm._s(_vm._f("ellipsis8")(item.stationName)))]):(item.stationType && item.stationType != '---')?_c('div',{staticClass:"stationName",staticStyle:{"height":"20px"}},[_vm._v(_vm._s(_vm._f("ellipsis10")(item.stationName)))]):_c('div',{staticClass:"stationName",staticStyle:{"height":"20px"}},[_vm._v(_vm._s(item.stationName))]),(item.hasMonitor != 0)?_c('van-image',{staticStyle:{"margin-top":"-2px","margin-left":"3px"},attrs:{"src":item.monitorStatus != 0 ? require('@/assets/img/site/live_onLine.png') : require('@/assets/img/site/live_outLine.png'),"width":"20","height":"20"}}):_vm._e()],1),(item.stationType && item.stationType != '---')?_c('div',{staticClass:"site-item_status",class:{
                                'site-item_status_one':item.stationType == '优质站点',
                        'site-item_status_two':item.stationType == '问题站点',
                        'site-item_status_three':item.stationType == '普通站点',
                        'site-item_status_four':item.stationType == '低效站点'}},[_vm._v(" "+_vm._s(item.stationType)+" ")]):_vm._e()]),_c('div',{staticClass:"stationLocate"},[_vm._v(_vm._s(item.stationLocate))]),_c('div',{staticClass:"flex"},[(item.stationTag)?_c('div',{staticClass:"site-item_tag",class:{'site-item_failure': item.stationTagCode == 'FAILURE'}},[_vm._v(_vm._s(item.stationTag))]):_vm._e(),(item.performanceRange && item.performanceRange != '---')?_c('div',{staticClass:"site-item_tag site-item_performanceRange"},[_vm._v(_vm._s(item.performanceRange))]):_vm._e(),(item.stationStatus)?_c('div',{staticClass:"site-item_tag",class:{
                  'site-item_stationStatus_one':item.stationStatus == '考察中',
                  'site-item_stationStatus_two':item.stationStatus == '加盟中',
                  'site-item_stationStatus_three':item.stationStatus == '已加盟',
                  'site-item_stationStatus_four':item.stationStatus == '已签约',
                  'site-item_stationStatus_five': (item.stationStatus == '签约未通过' || item.stationStatus == '加盟未通过' ) }},[_vm._v(_vm._s(item.stationStatus))]):_vm._e()])]),(_vm.isShow_SiteList_ToBeAudited)?_c('div',{staticStyle:{"background-color":"#F2F2F2","width":"100%","height":"1px","margin-top":"12px"}}):_vm._e(),_c('div',{staticStyle:{"width":"100%","height":"24px","margin-top":"12px"}},[_c('div',{staticClass:"toStation"},[_vm._v("查看站点")]),(item.hasMonitor != 0)?_c('div',{staticClass:"toLive",on:{"click":function($event){$event.stopPropagation();return _vm.monitoringPageClick(item.stationNo)}}},[_vm._v("查看监控")]):_vm._e()])])])}),0)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }